import React, {Component} from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Rodal from 'rodal';
import MedicsPage from "./pages/medics";
import Header from "./components/header";
import Footer from "./components/footer";
import LoginPage from "./pages/login";
import {
  hrefLogin,
  HREF_PAGE_MY_PROFILE,
  hrefRecovery,
  HREF_REGISTER,
  HREF_PAGE_MEDIC_EDIT_TIMETABLES,
  HREF_PAGE_DASHBOARD,
  HREF_PAGE_TERMS,
  HREF_PAGE_PATIENTS,
  HREF_PAGE_CHANGE_PASSWORD,
  HREF_PAGE_MEDIC_EDIT_NON_WORKING_DAYS,
  HREF_PAGE_MEDIC,
  HREF_PAGE_ADD_MEDIC,
  HREF_PAGE_ADD_PATIENT,
  HREF_PAGE_ONLINE,
  HREF_PAGE_MY_MEDICS,
  HREF_PAGE_MY_INSURANCES,
  HREF_PAGE_VIDEOCALL,
  HREF_PAGE_MY_MEDIC_PROFILE,
  HREF_PAGE_SETTINGS,
  HREF_PAGE_ADD_CLINIC,
  HREF_PAGE_MEDIC_ADD_TIMETABLE,
  HREF_REGISTER_PATIENT,
  HREF_REGISTER_MEDIC,
  HREF_PAGE_PATIENT,
  HREF_PAGE_ADD_MEDICAL_INSURANCE,
  HREF_PAGE_ADD_MEDICAL_INSURANCE_PATIENT,
  HREF_PAGE_PRESCRIPTIONS,
  HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION,
  HREF_PAGE_ADD_PRESCRIPTION,
  HREF_PAGE_ADD_PRESCRIPTION_PROFILE,
  HREF_PAGE_DOCUMENT_VISOR,
  HREF_PAGE_CERTIFICATES,
  HREF_PAGE_ADD_CERTIFICATE,
  HREF_PAGE_CLINIC_SETTINGS, HREF_PAGE_HELP,
  HREF_PAGE_VIEW_PRESCRIPTION_HASH,
  HREF_PAGE_APPOINTMENT_CALLER,
} from "./models/constants";
import RecoveryPage from "./pages/recovery";
import RegisterPatientPage from "./pages/registerPatient";
import MyProfilePage from "./pages/myProfile";
import MedicPage from "./pages/medic";
import MedicEditTimetablesPage from "./pages/medicEditTimetables";
import DashboardPage from "./pages/dashboard";
import TermsPage from "./pages/terms";
import PatientsPage from "./pages/patients";
import locales_es from "./locales/es";
import ChangePassword from "./pages/changePassword";
import NonWorkingDaysPage from "./pages/nonWorkingDays";
import AddMedicPage from "./pages/addMedic";
import AddPatientPage from "./pages/addPatient";
import OnlinePage from "./pages/online";
import MyMedicsPage from "./pages/myMedics";
import VideocallPage from "./pages/videocall";
import MyMedicProfilePage from "./pages/myMedicProfile";
import SettingsPage from "./pages/settings";
import AddClinicPage from "./pages/addClinic";
import ClearCachePage from "./pages/clearCache";
import MedicAddTimetablesPage from "./pages/medicAddTimetables";
import RegisterPage from "./pages/register";
import RegisterMedicPage from "./pages/registerMedic";
import PatientPage from "./pages/patient";
import MyMedicalInsurances from './pages/myMedicalInsurances';
import AddMedicalInsurance from "./pages/addMedicalInsurance";
import PrescriptionsPage from "./pages/prescriptions";
import AddProfessionalRegistrationPage from "./pages/addProfessionalRegistration";
import AddPrescription from "./pages/addPrescription";
import AddPrescriptionProfile from "./pages/addPrescriptionProfile";
import DocumentVisor from "./pages/documentVisor";
import CertificatesPage from "./pages/certificates";
import AddCertificate from "./pages/addCertificate";
import ClinicSettingsPage from "./pages/settingsClinic";
import HelpPage from "./pages/help";
import NewPatientPage from "./pages/newPatient";
import AppointmentCallerPage from "./pages/appointmentCaller";
import HashVisorPage from "./pages/hashVisor";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPath: 'undefined',
      visible: false,
      modalTitle: '',
      modalContent: '',
      modalActions: null,
      loadingConfig: true
    };

  }

  onChangeRouter() {
    if (this.state.currentPath !== window.location.pathname) {
      this.setState({
        currentPath: window.location.pathname
      });
    }
  }

  /* MODAL Functions */
  showModal(title, content, customStyles, actions) {
    this.setState({modalTitle: title});
    this.setState({modalContent: content});
    this.setState({customStyles});
    this.setState({modalActions: actions});
    const regex = /Failed to fetch/igm;
    regex.test(content) ? window.showFailedToFetchModal() : this.show();
  }

  show() {
    this.setState({visible: true});
  }

  hide() {
    this.setState({
      visible: false,
      modalTitle: '',
      modalContent: '',
      modalActions: null,
    });
  }

  //

  render() {

    const {currentPath} = this.state;

    const bodyStdClassName = 'kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-aside--enabled kt-aside--left kt-aside--fixed kt-aside--offcanvas-default';

    const classNames = {
      'undefined': {
        gridClassName: 'kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page'
      }, [hrefLogin]: {
        gridClassName: 'kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2'
      }, [HREF_REGISTER]: {
        gridClassName: 'kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2'
      }, [hrefRecovery]: {
        gridClassName: 'kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2'
      }, [HREF_PAGE_VIDEOCALL]: {
        gridClassName: 'full'
      },
    };

    const regexVideocall = new RegExp(HREF_PAGE_VIDEOCALL);
    const regexMedicNewPatient = new RegExp(locales_es.newPatientURL);
    const regexAppointmentCaller = new RegExp(HREF_PAGE_APPOINTMENT_CALLER);

    const hasCustomClassname = regexVideocall.test(currentPath) || regexMedicNewPatient.test(currentPath) || regexAppointmentCaller.test(currentPath);

    const isHeaderNotVisible = currentPath === hrefLogin ||
      currentPath === HREF_REGISTER ||
      currentPath === hrefRecovery ||
      currentPath === HREF_PAGE_APPOINTMENT_CALLER ||
      hasCustomClassname;

    const isFooterNotVisible = currentPath === HREF_PAGE_APPOINTMENT_CALLER;

    return (<Router onChange={this.onChangeRouter()}>
        <div
          className={hasCustomClassname ? classNames[HREF_PAGE_VIDEOCALL].gridClassName : bodyStdClassName}>
          <noscript>You need to enable JavaScript to run this app.</noscript>
          {process.env.REACT_APP_ENV_MANTEINANCE_MODE === 'true' ? <div className="kt-grid kt-grid--hor kt-grid--root">
            <div
              className={classNames[currentPath] ? classNames[currentPath].gridClassName : classNames['undefined'].gridClassName}>
              <Header
                showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}/>
              <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                   id="kt_content">
                <div className="kt-container  kt-grid__item kt-grid__item--fluid">
                  <div className="alert alert-warning" role="alert">
                    <div className="alert-text">
                      Estamos trabajando en el sitio. Por favor, accede más tarde para seguir con tus consultas.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> : <div
            className={hasCustomClassname ? classNames[HREF_PAGE_VIDEOCALL].gridClassName : 'kt-grid kt-grid--hor kt-grid--root'}>
            <div
              className={hasCustomClassname ? classNames[HREF_PAGE_VIDEOCALL].gridClassName : classNames[currentPath] ? classNames[currentPath].gridClassName : classNames['undefined'].gridClassName}>

              {isHeaderNotVisible ? null :
                <Header
                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}/>}
              <div
                className={hasCustomClassname ? classNames[HREF_PAGE_VIDEOCALL].gridClassName : 'kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor'}
                id="kt_content">

                <div
                  className={hasCustomClassname ? classNames[HREF_PAGE_VIDEOCALL].gridClassName : 'kt-container  kt-grid__item kt-grid__item--fluid'}>

                  <Switch>
                    <Route exact path={hrefLogin} render={(props) => <LoginPage {...props}
                                                                                showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={hrefRecovery} render={(props) => <RecoveryPage {...props}
                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_REGISTER} render={(props) => <RegisterPage {...props}
                                                                                       showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_REGISTER_PATIENT} render={(props) => <RegisterPatientPage {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_REGISTER_MEDIC} render={(props) => <RegisterMedicPage {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_MY_PROFILE} render={(props) => <MyProfilePage {...props}
                                                                                               showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_MY_MEDIC_PROFILE} render={(props) => <MyMedicProfilePage {...props}
                                                                                                          showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC_ADD_TIMETABLE}/:id`}
                           render={(props) => <MedicAddTimetablesPage {...props}
                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                           />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC_EDIT_TIMETABLES}/:id`}
                           render={(props) => <MedicEditTimetablesPage {...props}
                                                                       showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                           />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC_EDIT_TIMETABLES}/:id/:timetableId`}
                           render={(props) => <MedicEditTimetablesPage {...props}
                                                                       showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                           />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC_EDIT_NON_WORKING_DAYS}/:id`}
                           render={(props) => <NonWorkingDaysPage {...props}
                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                           />}/>
                    <Route exact path={HREF_PAGE_DASHBOARD} render={(props) => <DashboardPage {...props}
                                                                                              showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_TERMS} render={(props) => <TermsPage {...props}
                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_HELP} render={(props) => <HelpPage {...props}
                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_SETTINGS} render={(props) => <SettingsPage {...props}
                                                                                            showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_CLINIC_SETTINGS} render={(props) => <ClinicSettingsPage {...props}
                                                                                            showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_PATIENTS} render={(props) => <PatientsPage {...props}
                                                                                            showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_PRESCRIPTIONS} render={(props) => <PrescriptionsPage {...props}
                                                                                            showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_ADD_PRESCRIPTION} render={(props) => <AddPrescription {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_PRESCRIPTION}/:patientId`} render={(props) => <AddPrescription {...props}
                                                                                                       showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_CERTIFICATES} render={(props) => <CertificatesPage {...props}
                                                                                            showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_ADD_CERTIFICATE} render={(props) => <AddCertificate {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_CERTIFICATE}/:patientId`} render={(props) => <AddCertificate {...props}
                                                                                                                       showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_DOCUMENT_VISOR} render={(props) => <DocumentVisor {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_ADD_PRESCRIPTION_PROFILE} render={(props) => <AddPrescriptionProfile {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_PRESCRIPTION_PROFILE}/:id`} render={(props) => <AddPrescriptionProfile {...props}
                                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION}`} render={(props) => <AddProfessionalRegistrationPage {...props}
                                                                                                   showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION}/:id`} render={(props) => <AddProfessionalRegistrationPage {...props}
                                                                                                                                         showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_PATIENT}/:id`} render={(props) => <PatientPage {...props}
                                                                                                   showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={HREF_PAGE_CHANGE_PASSWORD} render={(props) => <ChangePassword {...props}
                                                                                                     showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC}/:id`} render={(props) => <MedicPage {...props}
                                                                                               showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_MY_MEDICS}`} render={(props) => <MyMedicsPage {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_MY_INSURANCES}`} render={(props) => <MyMedicalInsurances {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_MEDICAL_INSURANCE}`} render={(props) => <AddMedicalInsurance {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_MEDICAL_INSURANCE}/:medical_insurance_id`} render={(props) => <AddMedicalInsurance {...props}
                                                                                                                                 showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_MEDICAL_INSURANCE_PATIENT}/:patient_id`} render={(props) => <AddMedicalInsurance {...props}
                                                                                                                                           showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ONLINE}/:medic_id/:patient_id`}
                           render={(props) => <OnlinePage {...props}
                                                          showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                           />}/>
                    <Route exact path={`${HREF_PAGE_VIDEOCALL}/:hash`} render={(props) => <VideocallPage {...props}
                                                                                                         showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_MEDIC}`} render={(props) => <AddMedicPage {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_MEDIC}/:userId`} render={(props) => <AddMedicPage {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_MEDIC}/:medicId/${locales_es.newPatientURL}`} render={(props) => <NewPatientPage {...props}
                                                                                                  showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_CLINIC}/:medicId`} render={(props) => <AddClinicPage {...props}
                                                                                                             showMainModal={(title, content, customStyles) => this.showModal(title, content, customStyles)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_ADD_CLINIC}/:medicId/:clinicId`}
                           render={(props) => <AddClinicPage {...props}
                                                             showMainModal={(title, content, customStyles) => this.showModal(title, content, customStyles)}
                           />}/>
                    <Route exact path={`${HREF_PAGE_ADD_PATIENT}`} render={(props) => <AddPatientPage {...props}
                                                                                                      showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_APPOINTMENT_CALLER}`} render={(props) => <AppointmentCallerPage {...props}
                                                                             showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path="/clear-cache" render={(props) => <ClearCachePage {...props}
                                                                                        showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`/:id`} render={(props) => <MedicPage {...props}
                                                                             showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path={`${HREF_PAGE_VIEW_PRESCRIPTION_HASH}/:hash`} render={(props) => <HashVisorPage {...props}
                                                                             showMainModal={(title, content, customStyles, actions) => this.showModal(title, content, customStyles, actions)}
                    />}/>
                    <Route exact path="*" render={(props) => <MedicsPage {...props}
                                                                         showMainModal={(title, content, customStyles) => this.showModal(title, content, customStyles)}
                    />}/>
                  </Switch>
                </div>
              </div>
            </div>
          </div>}


          {hasCustomClassname || isFooterNotVisible ? null : <Footer/>}

          <Rodal width={window.screen && window.screen.availWidth ? window.screen.availWidth * 0.7 : '300'}
                 customStyles={this.state.customStyles}
                 visible={this.state.visible} onClose={() => this.hide()}>
            <h4 className="rodal-title">{this.state.modalTitle}</h4>
            {this.state.modalContent ? typeof this.state.modalContent === 'object' ? this.state.modalContent :
              <div className="rodal-body" dangerouslySetInnerHTML={{
                __html: this.state.modalContent
              }}/> : null}
            <div className="rodal-footer">
              {this.state.modalActions === null ? null : this.state.modalActions && this.state.modalActions.length ? this.state.modalActions.map(action =>
                <button className={`${action.class || 'btn btn-primary'}`} type="button"
                        onClick={() => action.method && action.method() || this.hide()}>{action.label}
                </button>) : <button className="btn btn-primary" type="button"
                                     onClick={() => this.hide()}>{locales_es.accept}
              </button>}
            </div>
          </Rodal>
        </div>
      </Router>);
  }
}

export default App;
