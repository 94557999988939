import React, { useEffect, useState } from 'react';
import './styles.css';
import whatsappIcon from './whatsapp-icon.svg'; // Asegúrate de tener el ícono en tu proyecto

const WhatsAppButton = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('whatsappTooltipClosed')) {
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => {
    setShowTooltip(false);
    localStorage.setItem('whatsappTooltipClosed', 'true');
  };

  return (
    <div className="whatsapp-container">
      <a
        className="whatsapp-link"
        // href="https://wa.me/5491166091789"
        // href="https://wa.me/5491161657123"
        href="https://wa.me/5491161657123?text=Hola,%20tengo%20una%20consulta%20sobre%20el%20uso%20de%20la%20plataforma%20Medicloud"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="whatsapp-icon" src={whatsappIcon} alt="WhatsApp" />
      </a>
      {showTooltip && (
        <div className="whatsapp-tooltip" onClick={handleCloseTooltip}>
          <span className="tooltip-close">
            &times;
          </span>
          ¡Contáctanos por cualquier duda que tengas, estamos para ayudarte! (Consultas sobre el uso de la plataforma)
        </div>
      )}
    </div>
  );
};

export default WhatsAppButton;
