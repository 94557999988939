import React, {Component} from 'react';
import './styles.css';
import locales_es from '../../locales/es';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import failedAnimation from './../../lotties/failed-cross.json';
import pengindPaymentAnimation from './../../lotties/pending-payment.json';
import Lottie from "react-lottie";
import {PAYMENT_STATUS_PENDING} from "../../models/constants";

class HashVisorPage extends Component {
  constructor(props) {
    super(props);

    this.api = new APIService();
    this.helpers = new Helpers();

    this.state = {
      hash: props.match.params.hash,
      document: null,
    }
  }

  componentDidMount() {
    this.api.getMedicalPrescriptionByHash(this.state.hash).then(res => {
      console.log(res)
      const data = res.data;
      this.setState({
        document: data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  render() {
    const {document, hash} = this.state;

    const animationSize = 150;

    const animationOptionsFailed = {
      loop: false,
      autoplay: true,
      animationData: failedAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const animationOptionsPending = {
      loop: true,
      autoplay: true,
      animationData: pengindPaymentAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div class="kt-portlet pb-5 pt-5">
        {!hash ?
          <h1>{locales_es.noHashFound}</h1>
          : document === null ? <Spinner/>
            : document && document.full_file_url ?
              <iframe className="woopi-document-visor-iframe" src={document.full_file_url}
                      title={`Document ${document.id}`}
                      width="100%" height="600px"/>
              : document.payment_status === PAYMENT_STATUS_PENDING ?
                <div class="container">
                  <div className="row">
                    <div className="col text-center mb-3">
                      <Lottie
                        options={animationOptionsPending}
                        height={animationSize}
                        width={animationSize}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <h4 class="text-center">{locales_es.mustPayPrescription}</h4>
                      <a href={document.mp_init_point}
                         className="btn btn-lg btn-brand btn-elevate btn-pill mt-4">{locales_es.pay}</a>
                    </div>
                  </div>
                </div>
                :
                <div class="container">
                  <div className="row">
                    <div className="col text-center">
                      <Lottie
                        options={animationOptionsFailed}
                        height={animationSize}
                        width={animationSize}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <h4 class="text-center">{locales_es.prescriptionNotFound}</h4>
                    </div>
                  </div>
                </div>
        }
      </div>
    );
  }
}

export default HashVisorPage;
