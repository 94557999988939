import React, {Component} from 'react';
import {RWebShare} from 'react-web-share';
import './styles.css';
import SendEmailPrescriptions from "../../components/SendEmailPrescriptions";
import locales_es from '../../locales/es';
import {MEDICAL_PRESCRIPTION_TYPE} from "../../models/constants";
import ChargePrescriptions from "../../components/ChargePrescriptions";

class DocumentVisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailOverlay: false,
      showPaymentOverlay: false,
    }
  }

  setShowEmailOverlay(bool) {
    this.setState({
      showEmailOverlay: bool
    })
  }

  setShowPaymentOverlay(bool) {
    this.setState({
      showPaymentOverlay: bool
    })
  }

  render() {
    const {documents, emails, prescriptionType} = this.props.location.state;
    const {showEmailOverlay, showPaymentOverlay} = this.state;

    return (
      <div>
        {showEmailOverlay && (
          <SendEmailPrescriptions
            prescriptionType={prescriptionType}
            documents={documents}
            emails={emails}
            showMainModal={this.props.showMainModal}
            onClose={() => this.setShowEmailOverlay(false)}
          />
        )}
        {showPaymentOverlay && (
          <ChargePrescriptions
            prescriptionType={prescriptionType}
            documents={documents}
            emails={emails}
            showMainModal={this.props.showMainModal}
            onClose={() => this.setShowPaymentOverlay(false)}
          />
        )}
        {documents.map((doc, index) => (
          <div key={index}>
            <iframe className="woopi-document-visor-iframe" src={doc.pdfSource.uri} title={`Document ${index}`}
                    width="100%" height="600px"/>
          </div>
        ))}
        {prescriptionType === MEDICAL_PRESCRIPTION_TYPE && documents.length === 1 ?
          <div className="text-center mt-3" onClick={() => this.setShowPaymentOverlay(true)}>
            <button type="button" className="btn btn-brand btn-elevate btn-pill btn-elevate-air"><i
              class="la la-money"/> Cobrar esta prescripción
            </button>
          </div> : null}
        <div className="text-center mt-3" onClick={() => this.setShowEmailOverlay(true)}>
          <button type="button" className="btn btn-success btn-elevate btn-pill btn-elevate-air"><i
            class="la la-inbox"/> Enviar por E-mail
          </button>
        </div>
        <div className="text-center mt-3">
          <a
            href={`https://wa.me/?text=Aquí están sus prescripciones ${documents.map(doc => doc.pdfSource.uri).join(', ')}`}
            target="_blank" className="btn btn-outline-brand btn-elevate btn-pill"><i
            class="la la-whatsapp"></i>{locales_es.whatsappShareButtonText}</a>
        </div>
        <div className="text-center mt-3">
          <RWebShare
            closeText={locales_es.close}
            data={{
              text: 'Aquí están sus prescripciones',
              url: documents.map(doc => doc.pdfSource.uri).join(', '),
              title: 'Documentos Compartidos de MediCloud',
            }}
            onClick={() => console.log('Compartido exitosamente')}
          >
            <button className="btn btn-outline-accent btn-elevate btn-pill"><i
              class="la la-paper-plane"></i>{locales_es.rnShareButtonText}</button>
          </RWebShare>
        </div>
      </div>
    );
  }
}

export default DocumentVisor;
