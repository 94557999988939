// EmailOverlay.js
import React, {useEffect, useState} from 'react';
import './styles.css';
import Helpers from "../../modules/helpers";
import locales_es from '../../locales/es';
import APIService from "../../modules/apiService";
import {MEDICAL_PRESCRIPTION_TYPE, PRESCRIPTION_CERTIFICATE_TYPE} from "../../models/constants";
import Loading from "../loading";

const SendEmailPrescriptions = ({onClose, showMainModal, prescriptionType, emails, documents}) => {
  const api = new APIService();
  const helpers = new Helpers();
  const [email, setEmail] = useState('');
  const [additionalEmails, setAdditionalEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddEmail = () => {
    if (email) {
      if (helpers.validateEmail(email)) {
        setAdditionalEmails([...additionalEmails, email]);
        setEmail('');
      } else {
        showMainModal(locales_es.errorModal.title, locales_es.register.errorEmail);
      }
    }
  };

  const handleRemoveEmail = (indexToRemove) => {
    setAdditionalEmails(additionalEmails.filter((_, index) => index !== indexToRemove));
  };

  const handleSend = () => {
    if (!additionalEmails.length) {
      showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithAtLeastOneEmail);
      return;
    }
    setLoading(true);
    const parsedEmails = ([...additionalEmails].filter(Boolean)); // Filter out any empty strings
    if (prescriptionType === MEDICAL_PRESCRIPTION_TYPE) {
      api
        .postPrescriptionEmail(documents, {emails: parsedEmails})
        .then((res) => {
          setLoading(false);
          showMainModal(locales_es.successModal.title, res.message);
          onClose();
        })
        .catch((err) => {
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
          setLoading(false);
        });
    } else if (prescriptionType === PRESCRIPTION_CERTIFICATE_TYPE) {
      api
        .postCertificateEmail(documents, {emails: parsedEmails})
        .then((res) => {
          setLoading(false);
          showMainModal(locales_es.successModal.title, res.message);
          onClose();
        })
        .catch((err) => {
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
          setLoading(false);
        });
    } else {
      showMainModal(locales_es.errorModal.title, locales_es.anErrorHasOcurred);
      setLoading(false);
    }
  };

  // Cargar correos electrónicos previos si se pasan como props
  useEffect(() => {
    if (emails && emails.length > 0) {
      setAdditionalEmails(emails);
    }
  }, [emails]);

  return (<div className="email-overlay">
    {loading ? <Loading/> : null}
    <div className="email-overlay-content">
      <div class="kt-notification-v2__item-icon text-center">
        <i class="flaticon-paper-plane-1 kt-font-success"></i>
      </div>
      <h3 className="mt-3 mb-3">Agregar direcciones de correo</h3>
      <p className="p-3">Ingrese los correos electrónicos a los que desea enviar los documentos.</p>
      <div className="email-input-group input-group">
        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Ingrese un correo electrónico"
          className="email-input form-control"
        />
        <button onClick={handleAddEmail} type="button" className="btn btn-outline-brand btn-elevate btn-icon"><i
          class="la la-plus"></i></button>
      </div>
      {additionalEmails.map((addedEmail, index) => (<div key={index} className="added-email">{addedEmail}
        <button type="button"
                onClick={() => handleRemoveEmail(index)}
                className="added-email-remove-btn btn btn-outline-hover-danger btn-sm btn-icon btn-circle m-1"><i
          class="fa fa-times"></i></button>
      </div>))}
      <div className="email-overlay-actions">
        <button onClick={handleSend} className="btn btn-success btn-elevate btn-pill">Enviar</button>
        <button onClick={onClose} className="btn btn-outline-danger btn-pill">Cancelar</button>
      </div>
    </div>
  </div>);
};

export default SendEmailPrescriptions;
