import React, {useEffect, useState} from 'react';
import locales_es from '../../locales/es';
import Loading from "../loading";
import Helpers from "../../modules/helpers";
import {
  HREF_PAGE_PATIENT,
  PRESCRIPTION_ERROR_INVALID_CREDENTIAL,
  PRESCRIPTION_ERROR_INVALID_CREDENTIAL_CHARS_LENGTH, PRESCRIPTION_ERROR_INVALID_CUIT
} from "../../models/constants";
import Form from "../form";
import Spinner from "../spinner";
import APIService from "../../modules/apiService";

const PrescriptionErrorHandler = ({onClose, showMainModal, patientId, prescriptionError, sendWithNoInsurance, retry}) => {
  const api = new APIService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);
  const [cuit, setCuit] = useState(null);
  const [userData, setUserData] = useState({});

  const handleChange = state => ev => {
    if (state === 'cuit') {
      setCuit(ev.target.value);
    }
  };

  const inputs = [
    {
      label: locales_es.cuit,
      placeholder: locales_es.cuit,
      id: 1,
      state: 'cuit',
      value: cuit,
      type: 'cuit',
      required: false,
      wrapperCustomClassName: 'col-12 col-sm-6 m-auto pt-3 pb-3 text-center',
    }
  ];

  const updateCuit = () => {
    setLoading(true);
    api.updateUser({...userData.user, cuit}).then(() => {
      setLoading(false);
      onClose();
      retry && retry();
    }).catch(err => {
      setLoading(false);
      showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    });
  }

  useEffect(() => {
    api.getUserMe().then(res => {
      setCuit(res.data?.cuit ?? '');
      setUserData(res.data);
    }).catch(err => {
      showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    });
  }, []);

  return (<div className="email-overlay">
    {loading ? <Loading/> : null}

    <div className="email-overlay-content" style={{maxWidth: 600}}>
      {/*<p className="p-3">{JSON.stringify(prescriptionError)}</p>*/}
      <div class="kt-notification-v2__item-icon bg-secondary text-center">
        <i class="flaticon2-warning"></i>
      </div>
      {prescriptionError && (prescriptionError.message || prescriptionError.errors?.length) ?
        <h4 className="m-4">{helpers.getErrorMsg(prescriptionError)}</h4> :
        <h3 className="m-4">Hay un inconveniente con tu receta</h3>}
      {prescriptionError && prescriptionError.error_code ?
        <>
          {
            prescriptionError.error_code === PRESCRIPTION_ERROR_INVALID_CUIT &&
            <>
              <div className="mt-3">
                {cuit === null ? <Spinner/> :
                  <Form
                    inputs={inputs}
                    handleChange={handleChange}
                    onSubmit={updateCuit}
                    onSubmitButtonText={locales_es.saveNewCuitAndRetry}
                    wrapper={false}
                  />
                }
              </div>
              <div className="m-3">
                <button onClick={sendWithNoInsurance}
                        className="btn btn-warning btn-pill">{locales_es.createPrescriptionWithoutInsurance}
                </button>
              </div>
              <div className="m-3">
                <button onClick={onClose} className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
              </div>
            </>
          }
          {
            prescriptionError.error_code === PRESCRIPTION_ERROR_INVALID_CREDENTIAL &&
            <>
              <div className="m-3">
                <button onClick={sendWithNoInsurance}
                        className="btn btn-warning btn-pill">{locales_es.createPrescriptionWithoutInsurance}
                </button>
              </div>
              <div className="m-3">
                <button onClick={onClose} className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
              </div>
            </>
          }
          {
            (prescriptionError.error_code === PRESCRIPTION_ERROR_INVALID_CREDENTIAL_CHARS_LENGTH || prescriptionError.error_code === PRESCRIPTION_ERROR_INVALID_CREDENTIAL) &&
            <>
              <h4 className="pt-5 pb-2 kt-font-bolder kt-font-info">El número de credencial está mal. Podés revisarlo
                vos, o
                pedirle a tu paciente que lo haga, antes de volver a intentarlo. O bien podés hacerle la receta sin
                cobertura, de forma particular.</h4>
              <div className="m-3">
                <a href={`${HREF_PAGE_PATIENT}/${patientId}`} target="_blank"
                   className="btn btn-success btn-elevate btn-pill">Ver paciente
                </a>
              </div>
              <div className="m-3">
                <button onClick={sendWithNoInsurance}
                        className="btn btn-warning btn-pill">{locales_es.createPrescriptionWithoutInsurance}</button>
              </div>
              <div className="m-3">
                <button onClick={onClose} className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
              </div>
            </>
          }
        </>
        :
        <>
          <button onClick={onClose} className="btn btn-brand btn-pill">{locales_es.close}</button>
        </>
      }
    </div>
  </div>);
};

export default PrescriptionErrorHandler;
