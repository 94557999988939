import esLocale from '@fullcalendar/core/locales/es';

export const langCode = 'es';
export const fcLocale = esLocale;

export const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',];
export const WEEKDAYS_LONG = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',];
export const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];

const locales_es = {
  "appName": "MediCloud",
  "appointmentTimeRemaining": "Tiempo restante del turno",
  "hereWeGo": "¡Allá vamos..!",
  "onlyYouIsConnected": "No hay nadie más conectado que tú",
  "addToCalendar": "Agregar a tu calendario",
  "scheduleNotificationsDetailItems": {
    "email_sent_at": "✉️ Enviado", "email_scheduled_at": "⏰️ Programado para", "email_opened_at": "✅ Abierto y/o leído",
  },
  "scheduleNotificationsDetailTitle": "Detalle de la notificación",
  "scheduleNotifications": {
    "first_notification": "✉️ Confirmación de Turno",
    "reminder": "✉️ Recordatorio",
    "appointment_videocall_now": "✉️ Recordatorio Videollamada",
  },
  "appointmentSuccess": {
    "title": "¡Excelente! ¡Ya tenés tu turno!",
    "text1": "Recibirás toda la información necesaria a tu casilla de correo, aunque puedes volver siempre a este sitio para consultar los datos del turno.",
    "text2": "RECUERDA revisar tu carpeta de correo no deseado o SPAM",
    "text3": "Marca los correos de MediCloud como \"no spam\" o \"de confianza\", para que puedas recibir las notificaciones del servicio correctamente.",
    "text4": "Recibirás toda la información necesaria a tu casilla de correo, aunque puedes volver siempre a este sitio para consultar los datos del turno.",
    "text5": "RECUERDA revisar tu carpeta de correo no deseado o SPAM",
  },
  "appointmentScheduled": {
    "title": "¡Bien! ¡Tenés reservado el turno!",
    "text1": "Revisa las opciones de pago disponibles",
    "text2": "Este turno es tuyo, pero postergar el pago demasiado tiempo podría hacer que el profesional finalmente se lo asigne a otra persona, excepto aquellos casos en los que coordinas el pago directo con el profesional",
    "text3": "Estas son las opciones de pago disponibles",
  },
  "bankTransferDataModal": {
    "title": "Acá están los datos para la transferencia",
    "subtitle": "Avisale al médico cuando hayas hecho el depósito para que pueda terminar de confirmarte el turno",
  },
  "thisIsWhatYouHaveToPay": "Esto es lo que tienes que abonar",
  "beforePaymentAmountDisclaimer": "Este importe corresponde a un porcentaje total o parcial",
  "important": "Importante",
  "timetableDeleteDisabled": "No puedes borrar esta franja de atención. Contáctate con el administrador.",
  "timetableEditionDisabled": "No puedes editar esta franja de atención. Contáctate con el administrador.",
  "connectedUsers": "Usuarios Conectados",
  "shareScreen": "Compartir pantalla",
  "unshareScreen": "Dejar de compartir pantalla",
  "enableMic": "Activar Micrófono",
  "disableMic": "Desactivar Micrófono",
  "enableCamera": "Activar Cámara",
  "disableCamera": "Desactivar Cámara",
  "medicalAppointment": "Turno Médico",
  "noAdditionalInfoToShow": "Este turno no tiene información adicional",
  "appointmentInformation": "Información del Turno",
  "seeLocation": "Ver Ubicación",
  "exit": "Salir",
  "noSingleTimetables": "No tienes días eventuales de atención activos",
  "preview": "Vista previa",
  "calendarConfiguration": "Configuración calendario",
  "reference": "Referencia",
  "sendImage": "Enviar imagen",
  "videocalAppointment": "Turnos con opción online o videollamada",
  "qualifyService": "Calificar el servicio",
  "externalVideocallTitle": "Haga click para ingresar a la videollamada",
  "joinVideocallTitle": "¡Estamos listos para comenzar!",
  "thanksForUsingOurServices": "Gracias por elegir nuestros servicios",
  "choosePatient": "Elegir paciente",
  "searchAPatientToAssignAppointment": "Busca un paciente para asignar un turno",
  "blockAppointment": "Bloquear turno",
  "appointmentStatus": "Estado del Turno",
  "paymentStatusText": "Estado del Pago",
  "appointmentInfo": "Información del turno",
  "assignAppointment": "Brindar turno",
  "assignExtraAppointment": "Brindar sobreturno",
  "slugname": "Nombre de la cuenta",
  "slugnamePlaceholder": "Usa un nombre fácil para tu perfil",
  "prefixName": "Prefijo",
  "prefixNamePlaceholder": "Por ej.: Dr., Dra., Lic., Ing., etc.",
  "medicPrefix": "Dr/a.",
  "requiredFields": "Campos requeridos",
  "required": "Requerido",
  "addClinic": "Agregar lugar de atención",
  "selectMedicalInsurance": "Seleccione cobertura médica",
  "medicalInsurance": "Cobertura médica",
  "patientMedicalInsurances": "Coberturas médicas del paciente",
  "medicalInsurances": "Coberturas médicas",
  "medicalInsurancePlan": "Plan médico",
  "medicalInsuranceNumber": "Número de afiliado",
  "addMedicalInsurance": "Agregar credencial nueva",
  "updateYourMedicalInsurancesToGetOnlinePrescriptions": "Ingresá tus coberturas médicas, para poder recibir tus recetas de forma más ágil 😎",
  "alreadyHaveMedicalInsurances": "Las tengo al día",
  "notNow": "Ahora no",
  "addOverbooking": "Añadir sobreturno",
  "addMedic": "Agregar médico",
  "addPatient": "Agregar paciente",
  "editPatient": "Editar paciente",
  "addNewPatient": "Agregar paciente nuevo",
  "newPatientURL": "nuevo-paciente",
  "totals": "Totales",
  "announcementTitle": "Título del anuncio",
  "announcementText": "Contenido del anuncio",
  "medicloudAnnouncement": "Anuncio Medicloud",
  "clinicAnnouncement": "Anuncio de la clínica",
  "medicalRecord": "Historia Clínica",
  "medicalStudies": "Estudios Complementarios",
  "removeThisAnnouncement": "Remover este anuncio",
  "removeAnnouncement": "Remover anuncio",
  "medicAgendaNotAvailable": "La agenda de este médico aún no está disponible",
  "medicAppointmentTypeNotAvailable": "O no dispone de turnos del tipo seleccionado",
  "appointmentsInConflictExplaining": "Aquí te mostraremos si tienes turnos tomados en los días que canceles, para que puedas convenir con tus pacientes la cancelación de los mismos y/o coordinar la atención para otro día.",
  "rescheduleAppointmentsInConflict": "Revisa los siguientes turnos y cancelalos una vez hayas coordinado con los pacientes la cancelación o un nuevo día para el turno.",
  "noAppointmentsInConflict": "No hay turnos en conflicto",
  "appointmentsInConflict": "Turnos en conflicto",
  "nonWorkingDays": "Días con Atención Suspendida o NO Laborables",
  "editNonWorkingDaysDisclaimer": "IMPORTANTE: NO cancele días de atención con turnos YA tomados sin antes avisar a los pacientes, dado que esos turnos NO se cancelan automáticamente ni se da aviso a los usuarios. Coordine con los pacientes primero antes de cerrar la agenda de ese día.",
  "editNonWorkingDays": "Editar Días NO Laborables",
  "cancelAppointment": "Cancelar Turno",
  "cancelAppointmentModal": {
    "title": "¿Cancelar Turno?",
    "subtitle": "¿Está seguro? Esta acción no se puede deshacer",
    "advice": "Notificaremos a todos los involucrados sobre tu decisión de cancelar este turno",
    "adviceMedicSecretary": "Notifica al paciente de tu decisión",
  },
  "cancelTimetableModal": {
    "title": "¿Cancelar Franja de Atención?",
    "subtitle": "¿Está seguro? Esta acción no se puede deshacer",
    "advice": "",
    "adviceMedicSecretary": "",
  },
  "saveChanges": "Guardas Cambios",
  "deleteAttentionPlace": "Eliminar lugar de atención",
  "deleteTimetable": "Eliminar franja",
  "enabledTimetable": "Franja habilitada",
  "footerEmailText": "Texto del email de confirmación de turno",
  "footerEmailTextPlaceholder": "El texto que escriba aquí se impactará en el e-mail de confirmación de turno. Sirve para hacer aclaraciones de todo tipo.",
  "interruptedAgendaProfileMedicDefaultText": "Esta agenda se encuentra deshabilitada para pacientes nuevos",
  "enablePostServiceTitle": "Mensajes Post Atención",
  "enablePostServiceDescription": "Aquí puedes configurar un mensaje que se envía por e-mail y/o por WhatsApp luego de que pongas un turno como 'Atendido' en el Estado del Turno",
  "interruptedAgendaTitle": "Interrupir agenda",
  "interruptedAgendaTextMandatory": "Necesita ingresar un texto explicativo para poder habilitar esta función",
  "interruptedAgendaText": "El texto que escriba aquí deshabilitará toda posibilidad de tomar turnos en la web y se mostrará en su lugar. Es decir que todas las franjas de atención quedarán deshabilitadas hasta tanto no borre el texto aquí ingresado.",
  "interruptedAgendaTextDescription": "El texto que escriba aquí deshabilitará toda posibilidad de tomar turnos en la web y se mostrará en su lugar. Es decir que todas las franjas de atención quedarán deshabilitadas hasta tanto no borre el texto aquí ingresado.",
  "interruptedAgendaTextPlaceholder": "El texto que ingreses aquí se muestra para impedir tomar turnos de este médico de forma momentánea o permanente.",
  "interruptedAgendaSend": "Guardar Texto",
  "interruptedAgendaForNoPatientsOnlyTitle": "Interrupir agenda para pacientes nuevos",
  "interruptedAgendaForNoPatientsOnlyText": "Texto de agenda interrumpida o derivada (Opcional)",
  "interruptedAgendaForNoPatientsOnlyTextDescription": "El texto que escriba aquí deshabilitará toda posibilidad de tomar turnos en la web a pacientes que no se hayan atendido previamente con usted, y se mostrará se mostrará cuando estos intenten tomar turnos.",
  "interruptedAgendaForNoPatientsOnlyTextPlaceholder": "El texto que ingreses aquí se muestra para impedir tomar turnos de este médico de forma momentánea o permanente para pacientes que no se hayan atendido previamente contigo. En caso de que no lo completes, se muestra un texto genérico de la plataforma.",
  "interruptedAgendaForNoPatientsOnlySend": "Guardar",
  "postServiceTimelapseText": "Tiempo que transcurre para enviar el mensaje luego del cambio de estado del turno a Atendido",
  "postServiceTimelapseTextPlaceholder": "Valor en horas.",
  "postServiceSubjectText": "Título del mensaje",
  "postServiceSubjectTextPlaceholder": "Escriba aquí un título para tu mensaje",
  "postServiceMessageText": "Mensaje",
  "postServiceMessageTextPlaceholder": "Escriba aquí el mensaje deseado",
  "observations": "Observaciones",
  "observationsPlaceholder": "Aquí puedes aclarar variaciones de precio de tus consultas, formas de tomar los turnos, etc.",
  "actions": "Acciones",
  "soon": "Muy pronto",
  "yourMedicProfile": "Tu perfil médico",
  "shareYourProfileToYourPatients": "Comparte tu perfil con tus pacientes para que puedan solicitar atención médica contigo",
  "deletedPatient": "Paciente eliminado",
  "noPatientsFound": "No se han encontrado pacientes",
  "noMorePatients": "No hay más pacientes",
  "noMorePrescriptions": "No hay más prescripciones",
  "noPatientsYet": "No tienes pacientes vinculados aún.",
  "freeAppointment": "Turno libre",
  "blockedAppointment": "Turno bloqueado",
  "blockedAppointmentInfo": "Turno bloqueado no disponible para pacientes de la plataforma",
  "underDevelopment": "En desarrollo... Muy pronto :)",
  "momentaryDisabledTimetablesEdition": "Momentáneamente la edición de horarios de atención es sólo posible para los planes del tipo Clínica o Centro Médico. Consulte con el administrador.",
  "nextAppointments": "Próximos Turnos",
  "announcements": "Anuncios",
  "shareLinkTitle": "Comparte tu perfil",
  "shareLinkDescription": "Haz click para copiar y comparte este link con tus pacientes para que puedan empezar a sacar turnos contigo",
  "sharePatientLinkTitle": "Tu paciente se dá de alta sólo si así lo deseas",
  "sharePatientLinkDescription": "Haz click para copiar y comparte este link con tus pacientes para que pueda completar tu ficha de paciente",
  "addAnnouncement": "Agregar Anuncio",
  "noAnnouncements": "No hay anuncios nuevos.",
  "announcementsSoon": "Muy pronto podrás recibir aquí los anuncios importantes del centro :)",
  "dashboard": "Inicio",
  "patientFullName": "Nombre Completo del Paciente",
  "theAppointmentPatientIsSomeoneelse": "El turno es para otra persona",
  "theAppointmentPatientIsMe": "El turno es para mí",
  "theAppointmentPatientIsForThisPatient": "El turno es para este paciente",
  "whoIsTheAppointmentPatient": "¿Para quién es el turno?",
  "youNeedToBeLoggedInToRequestAnAppointment": "Necesitas estar registrado para solicitar un turno",
  "youNeedToBeLoggedInAsAPatientToRequestAnAppointment": "Necesitas iniciar sesión con un usuario paciente para solicitar un turno",
  "selectAnAppointment": "Elige un turno",
  "externalLinks": "Enlaces Externos",
  "noExternalLinks": "Sin enlaces externos",
  "noAttentionSchedules": "No hay horarios de atención disponibles",
  "attentionSchedules": "Horarios de Atención",
  "attentionSingleSchedules": "Días de atención especial",
  "thereIsNoAvailableAppointmentsForTheSelectedDate": "No hay turnos disponibles para la fecha seleccionada",
  "availableAppointments": "Turnos Disponibles",
  "consultingAppointment": "Turnos de Consultorio",
  "virtualAppointment": "Turnos Virtuales - Videoconsulta/Consulta Online",
  "virtualAppointmentDescription": "La consulta se hace en línea con chat y videollamada, desde dónde te encuentres, a la hora del turno pactada. Recibirás un enlace por e-mail y/o podrás acceder a la consulta virtual desde tu panel de turnos.",
  "daysOfTheWeek": {
    "sunday": "Domingo",
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
  },
  "confirmAnnouncementRemoval": "¿Confirma que desea remover este anuncio?",
  "confirmLapseTimeRemoval": "¿Confirma que desea remover esta franja de atención?",
  "confirmAppointmentRemoval": "¿Confirma que desea remover este turno?",
  "confirmAppointmentUnlock": "¿Confirma que desea desbloquear este turno?",
  "addLapseTime": "Agregar Franja horaria",
  "lapseTime": "Franja horaria",
  "appointmentDuration": "Duración del turno",
  "limitTimePostAppointmentDescription": "Cantidad de horas mínimas para sacar un turno",
  "limitTimeCancelAppointmentDescription": "Cantidad de horas mínimas para cancelar un turno tomado",
  "inMinutes": "En minutos",
  "minutes": "Minutos",
  "inHours": "En horas",
  "hours": "Horas",
  "min": "Min",
  "consultingRoom": "Consultorio",
  "consultingRooms": "Consultorios",
  "consultingPlace": "Lugar de Atención",
  "enabled": "Habilitado",
  "disabled": "Deshabilitado",
  "deactivated": "Desactivado",
  "place": "Lugar",
  "prescriptionProfileAddress": "Dirección para la receta",
  "prescriptionProfileAddressPlaceholder": "La receta debe tener un domicilio completo válido",
  "attentionAddress": "Lugar de Atención",
  "timetables": "Horarios de Atención",
  "addTimetable": "Agregar Horario de Atención",
  "editTimetables": "Editar Horarios de Atención",
  "editSingleTimetables": "Días de Atención Eventuales",
  "editTimetable": "Editar Franja de Atención",
  "overviewTimetable": "Resumen de Franja de Atención",
  "administrateAppointments": "Gestionar Turnos",
  "requestAnAppointment": "Pedir un Turno",
  "bio": "Biografía",
  "bioPlaceholder": "Descripción del perfil del médico para el paciente",
  "website": "Sitio Web",
  "readMore": "Leer más",
  "disclaimerNotSharing": "No compartiremos esta información con nadie más",
  "selectIdentificationType": "Seleccione Tipo de Identificación",
  "user_type": {
    "patient": "Paciente", "medic": "Especialista", "secretary": "Gestor",
  },
  "hello": "Hola",
  "iAcceptTermsAndConditions": "Acepto los términos y condiciones",
  "doYouHaveAnAccountAlready": "¿Ya tienes cuenta?",
  "dontYouHaveAnAccount": "¿No tienes una cuenta?",
  "setAppointment": "Sacar turno",
  "seeProfile": "Ver perfil",
  "editInsurance": "Editar cobertura",
  "noMedicalInsurancesAvailables": "No tienes coberturas dadas de alta...",
  "noMedicalInsurance": "Sin cobertura",
  "noMedicsAvailable": "No hay profesionales disponibles...",
  "noInfoAvailable": "No hay información disponible...",
  "allSpecialists": "Todos los especialistas disponibles",
  "addSpecialist": "Agregar especialista",
  "specialist": "Especialista",
  "specialists": "Especialistas",
  "specialistProfile": "Perfil del Especialista",
  "addPatientToSpecialist": "Añadir paciente al especialista",
  "chooseSeats": "Elegir asientos",
  "tapToChoose": "Toca para elegir",
  "buy": "Comprar",
  "noStock": "Agotado",
  "noPresentationAvailable": "No hay funciones disponibles",
  "seat": "Asiento",
  "seats": "Asientos",
  "tickets": "Tickets",
  "selectedSeats": "Asientos seleccionados",
  "noSelectedSeats": "Sin asientos seleccionados",
  "total": "Total",
  "orderReview": "Resumen de la compra",
  "notDefined": "No definido",
  "hasNot": "No ingresado",
  "hasNotEmail": "E-mail no ingresado",
  "hasNotAccount": "Sin cuenta",
  "inviteUser": "Invítalo",
  "nameAndLastname": "Nombre y Apellido",
  "name": "Nombre",
  "floorDepartment": "Piso / Departamento",
  "lastname": "Apellido",
  "back": "Volver",
  "close": "Cerrar",
  "selectValue": "Seleccione...",
  "clickToPhoneCall": "Haz click para llamar",
  "editProfile": "EDITAR PERFIL",
  "settings": "Configuraciones",
  "clinicSettings": "Configuraciones del centro",
  "paymentMethods": "Medios de pago",
  "gatewayMethods": "Medios de cobro",
  "searchTypeSelector": {
    "searchByBrand": "Buscar por Marca", "searchByDrugs": "Buscar por Droga",
  },
  "global": {
    "errorTitle": "Error"
  },
  "recoveryPassword": "Recuperar Contraseña",
  "recovery": "Recuperar",
  "registerYourself": "Registrate",
  "loginYourself": "Inicia sesión",
  "registerNewSpecialist": "Registro de médico nuevo",
  "register": {
    "addClinic": "Añadir Lugar de Atención",
    "title": "Registro de paciente nuevo",
    "titleMedic": "Registro de médico nuevo",
    "button": "Registrar usuario",
    "errorName": "Ingrese un nombre",
    "errorEmail": "El E-Mail es inválido",
    "errorSpecialtyMissing": "Seleccione al menos una especialidad",
    "errorPassword": "Ingrese una contraseña",
    "errorTerms": "Debe aceptar los Términos y Condiciones",
    "errorTransferCategoryName": "Debe ingresar un nombre",
    "errorProfileImageMissing": "Debe añadir una foto suya",
    "errorPasswordRepeatNotMatched": "La contraseña y la contraseña repetida no coinciden",
  },
  "attentionPlaces": "Lugares de Atención",
  "addAttentionPlace": "Agregar Lugar de Atención",
  "editAttentionPlace": "Editar Lugar de Atención",
  "editThisAttentionPlace": "Editar este lugar de atención",
  "edit": "Editar",
  "delete": "Eliminar",
  "deactivate": "Desactivar",
  "menu": {
    "home": "Inicio",
    "configuration": "Configuración",
    "createPrescriptions": "Generar Recetas",
    "myMedics": "Mis Especialistas",
    "myChildren": "Mis Hijos",
    "myPayments": "Mis Medios de Pago",
    "myConsultations": "Mis Consultas",
    "consultations": "Consultas del Paciente",
    "myPatients": "Mis Pacientes",
    "myMovements": "Mis Movimientos",
    "myAppointments": "Mis Turnos",
    "FAQsCategories": "Preguntas Frecuentes",
  },
  "unassignMedicConfirmModal": {
    "unassignMedicTitle": "¿Está seguro de desvincularse de este médico?",
    "unassignMedicSubtitle": "Confirme que desea desvincular este médico",
  },
  "unassignChildMedicConfirmModal": {
    "unassignMedicTitle": "¿Está seguro de desvincular este médico de su hijo?",
    "unassignMedicSubtitle": "Confirme que desea desvincular este médico",
  },
  "myChildren": {
    "removeChildTitle": "¿Está seguro de remover este hijo?",
    "removeChildSubtitle": "Confirme que desea remover este hijo",
  },
  "myConsultations": {
    "removeConsultationTitle": "¿Está seguro de eliminar esta consulta?",
    "removeConsultationSubtitle": "Confirme que desea elimina esta consulta",
  },
  "myPayments": {
    "removePaymentTitle": "¿Está seguro de eliminar este medio de pago?",
    "removePaymentSubtitle": "Confirme que desea elimina este medio de pago",
  },
  "indicationsList": {
    "removeIndicationTitle": "¿Está seguro de eliminar esta indicación?",
    "removeIndicationSubtitle": "Recuerde que el paciente ya no podrá verla",
  },
  "signaturesList": {
    "removeSignatureTitle": "¿Está seguro de eliminar esta firma?",
    "removeSignatureSubtitle": "Recuerde que los pacientes ya no podrán verla ni estará disponible para firmar los documentos",
  },
  "stampsList": {
    "removeStampTitle": "¿Está seguro de eliminar este sello?",
    "removeStampSubtitle": "Recuerde que los pacientes ya no podrán verlo ni estará disponible para sellar los documentos",
  },
  "medicinesList": {
    "removeMedicineTitle": "¿Está seguro de quitar esta medicina de la receta actual?",
    "removeMedicineSubtitle": "Esta acción no puede deshacerse",
  },
  "prescriptionsList": {
    "removePrescriptionTitle": "¿Está seguro de eliminar esta receta?",
    "removePrescriptionSubtitle": "Recuerde que el paciente ya no podrá verla",
  },
  "medicalOrdersList": {
    "removeMedicalOrderTitle": "¿Está seguro de eliminar esta órden?",
    "removeMedicalOrderSubtitle": "Recuerde que el paciente ya no podrá verla",
  },
  "medicalCertificatesList": {
    "removeMedicalCertificateTitle": "¿Está seguro de eliminar este certificado?",
    "removeMedicalCertificateSubtitle": "Recuerde que el paciente ya no podrá verlo",
  },
  "viewConsultation": {
    "confirmConsultationTitle": "¿Está seguro de finalizar esta consulta?",
    "confirmConsultationSubtitle": "Confirme que desea finalizar esta consulta",
  },
  "imagePicker": {
    "title": "Cambiar foto de perfil...",
    "cancelButtonTitle": "Cancelar",
    "takePhotoButtonTitle": "Sacar una foto",
    "chooseFromLibraryButtonTitle": "Elegir de la galería de fotos",
  },
  "consultationStatus": {
    "created": "Creada",
    "confirmed": "Confirmada",
    "completed": "Completada",
    "canceled": "Cancelada",
    "rejected": "Rechazada",
    "payment_rejected": "Pago Rechazado",
    "pending_payment": "Pago Pendiente",
  },
  "paymentStatus": {
    "approved": "Aprobada",
    "pending": "Pendiente de revisión",
    "rejected": "Rechazada",
    "tokenized": "Pendiente",
    "payment_rejected": "Pago Rechazado",
    "pending_payment": "Pago Pendiente",
  },
  "movementStatus": {
    "completed": "Aprobado",
    "approved": "Aprobada",
    "pending": "Pendiente",
    "rejected": "Rechazada",
    "tokenized": "Procesando medio de pago",
    "payment_rejected": "Pago Rechazado",
    "pending_payment": "Pago Pendiente",
  },
  "possibleAssignmentPatientsTitle": "Pacientes que puedes vincular",
  "noAvailablePatientsToAssign": "No tienes pacientes para vincular con este médico",
  "myChildrenMedics": "Médicos de tus hijos",
  "adultsEnabled": "Atiende adultos",
  "childsEnabled": "Atiende niños",
  "title": "Título",
  "charge": "Cobro",
  "chargeType": "Tipo de Cobro",
  "description": "Descripción",
  "comments": "Comentarios",
  "specialty": "Especialidad",
  "medicalRegistrationNumber": "Matrícula/s",
  "medicalRegistrationNumberPlaceholder": "MN: 123456 / MP: 678901",
  "specialties": "Especialidades",
  "retry": "Reintentar",
  "issuedBy": "emitida por",
  "checkingYourPayments": "Comprobando sus medios de pago",
  "thisCouldTakeALong": "Esto puede demorar un poco...",
  "cardEndingIn": "Tarjeta terminada en",
  "cantUnassignMedicActiveConsultation": "No puedes desvincularte con un médico mientras tengas una consulta activa",
  "select": "Seleccionar",
  "bookAppointment": "Solicitar Turno",
  "myProfile": "Mi Perfil",
  "keepThisInformationUpToDate": "Mantén esta información actualizada",
  "myAppointments": "Mis Turnos",
  "seeAppointments": "Ver Turnos",
  "appointment": "Turno",
  "externalVideocallLink": "Enlace de videollamada externa",
  "externalVideocallLinkPlaceholder": "Ingrese aquí el enlace generado con Zoom, Skype, Teams, Meet, Hangouts, o el servicio que desee usar.",
  "appointmentType": "Tipo de Turno",
  "appointmentTypeName": ['', 'Presencial', 'Virtual', 'Mixto'],
  "appointments_average": "Turnos promedio / méd.",
  "appointments": "Turnos",
  "appointmentsFuture": "Próximos",
  "appointmentsPast": "Pasados",
  "hour": "Hora",
  "status": "Estado",
  "chat": "Chat",
  "thereIsNoMessagesInThisChat": "No hay mensajes todavía",
  "exportXLS": "Exportar Listado",
  "sendMessage": "Enviar Mensaje",
  "writeAMessage": "Escribe un mensaje",
  "messages": "Mensajes",
  "weight": "Peso en kgs.",
  "height": "Altura en cm.",
  "patientWeight": "Peso del paciente en kgs.",
  "consultationReasonTitle": "Motivo de la consulta",
  "consultationDescriptionTitle": "Descripción",
  "consultationInformation": "Información de consulta",
  "consultationPrice": "Valor de la consulta",
  "paymentOption": "Medio de pago",
  "freeConsultation": "Consulta sin cargo",
  "patientData": "Datos del paciente",
  "medicToAddConsultation": "Médico a consultar",
  "remove": "Quitar",
  "identificationType": "Tipo de Identificación",
  "accountType": "Tipo de Cuenta",
  "birth_date": "Cumpleaños",
  "singleTimetable": "Día de Atención Eventual",
  "DD/MM/YYYY": "DD/MM/AAAA",
  "start": "Inicia",
  "end": "Termina",
  "startDate": "Fecha de Inicio",
  "endDate": "Fecha de Terminación",
  "startDateInfo": "Fecha en la que esta franja empieza a ofrecer turnos.\nDejar en blanco para franja de tiempo indefinido",
  "endDateInfo": "Fecha en la que esta franja se deshabilita.\nDejar en blanco para franja de tiempo indefinido",
  "date_of_birth": "Fecha de Nacimiento",
  "attendedBy": "Atendido por",
  "date_of_birth_mask": "DD/MM/AAAA",
  "parentRole": "Rol con tu hijo",
  "presentation": "Presentación",
  "gender": "Género",
  "autoperceivedGender": "Género Autopercibido",
  "save": "Guardar",
  "saveNewCuit": "Guardar nuevo CUIT",
  "saveNewCuitAndRetry": "Guardar nuevo CUIT y reintentar",
  "clean": "Limpiar",
  "cleanResults": "Limpiar resultados",
  "cleanSearchResults": "Limpiar búsqueda",
  "send": "Enviar",
  "validate": "Validar",
  "valid": "Válido",
  "initConsultation": "Iniciar consulta",
  "seeActiveConsultation": "Ver consulta activa",
  "emptySearch": "Ingrese un texto para buscar",
  "notEnoughSearch": "Ingrese más de 3 caracteres para iniciar la búsqueda",
  "notEnoughChildren": "Debe ingresar los datos de su hijo.",
  "addYourFirstChild": "Ingresa ahora los datos de tu hijo.",
  "search": "Buscar...",
  "searchByMedicsOrSpeciality": "Busca médico y/o especialidad",
  "searchByPatientsNameOrLastname": "Busca pacientes nombre o apellido",
  "searchPatients": "Buscar pacientes",
  "signatures": "Firmas digitales",
  "signature": "Firma digital",
  "stamps": "Sellos personales",
  "stamp": "Sello personal",
  "clarifies": "Aclaraciones del medicamento...",
  "searchPrescription": "Buscar medicina...",
  "noChildren": "No tienes hijos ingresados en el sistema.",
  "noChildrenByPatient": "Este paciente no tienes hijos ingresados en el sistema.",
  "noIndication": "Esta consulta no tiene indicaciones.",
  "noSignature": "Usted no tiene ninguna firma digital configurada.",
  "noStamp": "Usted no tiene ningún sello configurado.",
  "noMedicines": "Esta receta no tiene medicinas añadidas.",
  "noPrescription": "Esta consulta no tiene recetas.",
  "noPrescriptions": "No se encontraron recetas.",
  "noMedicalOrder": "Esta consulta no tiene órdenes.",
  "noConsultation": "No tienes consultas en el sistema.",
  "noActiveConsultation": "No tienes consultas activas por el momento.",
  "noFAQs": "No hay preguntas frecuentes disponibles.",
  "noPayments": "No tienes medios de pago activos.",
  "paymentGatewayEcuador": "Pagos Procesados por PlaceToPay",
  "noMedicalCertificates": "Esta consulta no tiene certificados.",
  "noMovements": "Sin movimientos hasta el momento.",
  "noFutureAppointments": "Sin turnos futuros.",
  "noPastAppointments": "Sin turnos pasados.",
  "addYourChildren": "Ingresa tu hijo a la plataforma ahora",
  "addAnIndication": "Ingresa una indicación para el paciente",
  "addASignature": "Añade una firma digital para tus documentos",
  "addAStamp": "Añade un sello para tus documentos",
  "addAMedicine": "Añade una medicina para el paciente",
  "addAPrescription": "Ingresa una receta para el paciente",
  "requestAPrescription": "Solicitala a tu médico a través del chat",
  "patientMissing": "Selecciona un paciente para adjuntar esta receta",
  "addAnIndicationAtLeast": "Ingresa al menos una indicación para el paciente",
  "addAnMedicalOrder": "Ingresa una órden para el paciente",
  "requestAMedicalOrder": "Solicitala a tu médico desde el chat",
  "addAMedicalCertificate": "Solicitudes de estudios, certificados y otros",
  "otherPrescriptionsTitle": "Solicitudes de estudios, certificados y otros",
  "requestAMedicalCertificate": "Solicitalo a tu médico a través del chat",
  "addAnMedicalOrderAtLeast": "Ingresa al menos una órden para el paciente",
  "addYourConsultation": "Ingresa tu primera consulta",
  "addAPaymentMethod": "Agrega un medio de pago ahora.",
  "addPaymentMethod": "Agregar medio de pago",
  "availabilityInfoAboutMedicalDirectory": "Encender esta funcionalidad implica que los usuarios pacientes podrán encontrarte en la cartilla médica de Ánima e iniciar consultas médicas contigo.",
  "message": "Mensaje",
  "addChild": "Añadir hijo",
  "addConsultation": "Iniciar consulta",
  "consultation": "Consulta",
  "addPrescriptionProfile": "Agregar perfil de receta",
  "addProfessionalRegistrationJurisdiction": "Ingresar matrícula",
  "professionalRegistrationJurisdictions": "Jurisdicciones",
  "professionalRegistrationJurisdiction": "Jurisdicción",
  "prescriptionProfileProfession": "Profesión o Especialidad para el sello",
  "prescriptionProfileProfessionPlaceholder": "Texto libre para tus especialidades",
  "prescriptionProfileName": "Nombre para el perfil de la receta",
  "prescriptionProfileNamePlaceholder": "Un nombre reconocible, como 'Hospital', o 'Consultorio'",
  "professionalRegistrationType": "Tipo de matrícula profesional",
  "professionalRegistration": "Matrícula profesional",
  "professionalRegistrations": "Matrículas profesionales",
  "noProfessionalRegistrations": "No tienes matrículas dadas de alta",
  "prescriptionProfile": "Perfil de receta",
  "prescriptionProfiles": "Perfiles de receta",
  "noPrescriptionProfiles": "No tienes perfiles de receta dados de alta",
  "removeProfessionalRegistration": "Eliminar Matrícula",
  "addProfessionalRegistration": "Añadir matrícula profesional",
  "editProfessionalRegistration": "Editar matrícula profesional",
  "addCertificate": "Añadir prescripción",
  "addPrescription": "Generar receta",
  "addIndication": "Añadir indicación",
  "removeIndication": "Remover indicación",
  "addSignature": "Añadir firma digital",
  "removeSignature": "Remover firma digital",
  "writeStamp": "Escribe aquí tu sello personal",
  "addStamp": "Añadir sello",
  "removeStamp": "Remover sello ",
  "addMedicine": "Añadir medicina",
  "maxMedicineReached": "Se permiten 3 medicinas máximo",
  "addAnotherMedicine": "Añadir más medicina",
  "removeMedicine": "Remover medicina",
  "repeatPrescription": "Repetir receta",
  "repeatCertificate": "Repetir prescripción",
  "reusePrescription": "Reutilizar receta",
  "reuseCertificate": "Reutilizar prescripción",
  "seePrescription": "Ver Receta",
  "seeCertificate": "Ver prescripción",
  "addEmailField": "Añadir campo de E-mail",
  "removeEmailField": "Remover campo de E-mail",
  "editIndication": "Editar indicación",
  "editSignature": "Editar firma digital",
  "editPrescription": "Editar receta",
  "addMedicalOrders": "Añadir Órdenes Médicas",
  "diagnosis": "Diagnóstico",
  "freeText": "Texto libre",
  needToAddAMedicineAtLeast: 'Debes agregar al menos un medicamento',
  unlockYourPhoneToCreatePrescription:
    'Desbloquear el teléfono para emitir la receta',
  addPrescriptionDiagnosis: 'Añada aquí el diagnóstico de la receta',
  addPrescriptionFreeTextPlaceholder: 'Aquí puede generar recetas personalizadas, prescripción libres, indicaciones, etc.',
  addPrescriptionIndications: 'Añada aquí las indicaciones para el paciente',
  addPrescriptionComments:
    'Lugar para texto libre competente a cualquier aclaración general de la receta',
  "icd10Title": "Diagnóstico (Opcional)",
  "icd10AddTitle": "Buscar Diagnóstico",
  "icd10AddSuggestedTitle": "Diagnósticos Sugeridos",
  "icd10Search": "Ingresa un texto para buscar...",
  "icd10AddButton": "Agregar diagnóstico",
  "icd10RemoveButton": "Quitar diagnóstico",
  "code": "Código",
  "additionalTextFreeAndOptional": "Texto adicional (Libre y opcional)",
  "indications": "Indicaciones",
  "indication": "Indicación",
  "prescriptions": "Recetas",
  seePrescriptions: 'Ver Recetas',
  createPrescriptions: 'Generar recetas',
  creatingPrescription: 'Generando receta',
  creatingPrescriptions: 'Generando recetas',
  createdCertificate: 'Prescripción creada',
  createdCertificates: 'Prescripciones creadas',
  createdPrescription: 'Receta creada',
  createdPrescriptions: 'Recetas creadas',
  createdPrescriptionSuccessfully: 'Creación exitosa',
  "myPrescriptions": "Recetas",
  "myCertificates": "Otras prescripciones",
  "quantity": "Cantidad",
  "thisDoctorIsCallingYou": "Este médico está queriendo conectarse contigo",
  "globalConfigurations": "Configuraciones globales",
  hivPatient: 'Paciente HIV',
  extendedTreatment: 'Tratamiento prolongado',
  "medicines": "Medicinas",
  "createPrescription": "Generar receta",
  "prescription": "Receta",
  "medical_orders": "Órdenes de Estudio",
  "medical_order": "Órden de Estudio",
  "noSelectedMedicalOrdersTemplates": "No has seleccionado ningún ítem. Debes seleccionar al menos uno para poder continuar.",
  "addMedicalOrder": "Añadir órden",
  "removeMedicalOrder": "Remover órden",
  "editMedicalOrder": "Editar órden",
  "medical_certificates": "Certificados",
  "medical_certificate": "Certificado",
  "noSelectedMedicalCertificatesTemplates": "Debes seleccionar uno para poder continuar.",
  "addMedicalCertificate": "Añadir certificado",
  "removeMedicalCertificate": "Remover certificado",
  "selectMedicalCertificateTemplate": "Seleccione Certificado",
  "editMedicalCertificate": "Editar certificado",
  "viewMedicalCertificate": "Ver certificado",
  "viewDocument": "Ver documento",
  "inactiveConsultation": "Consulta Inactiva",
  "completeConsultation": "Finalizar Consulta",
  "youGot": "Tienes",
  "youGotNewMessage": "Tienes nuevos mensajes",
  "openChatToSeeMessages": "Abre el chat para ver los nuevos mensajes",
  "acceptVideocall": "Aceptar Videollamada",
  "rejectedVideocallByPatient": "Llamada rechazada. Le avisaremos a tu médico que no puedes atenderlo en estos momentos.",
  "rejectVideocall": "Rechazar Videollamada",
  "initVideocall": "Iniciar Videollamada",
  "enterVideocall": "Ingresar a la videollamada",
  "videocallLink": "Enlace videollamada",
  "joinVideocall": "Unirse a la Videollamada",
  "joinVideocallPrejoin": "Iniciar",
  "endedVideocallError": "Esta videollamada ha terminado. El médico puede iniciar una nueva si fuese necesario.",
  "loadingVideocall": "Cargando videollamada...",
  "enterExternalVideocall": "Ingresar a videollamada externa",
  "resumePlayback": "Haga click para restablecer el audio",
  "ifYouStillFacingIssues": "Si sigue experimentando problemas",
  "refreshThisPage": "refresque esta página",
  "enteringVideocall": "Ingresando a la videollamada...",
  "endingVideocall": "Cerrando videollamada...",
  "goToChat": "Ir al Chat",
  "requestVaccines": "Solicitar Vacunas",
  "requestCertificates": "Solicitar Certificados",
  "certificates": "Otras prescripciones",
  "createIndication": "Crear Indicación",
  "createMedicalOrder": "Crear Órden",
  "createCertificate": "Crear Certificado",
  "requestAppointment": "Solicitar Turno",
  "requestNewAppointment": "Solicitar Nuevo Turno",
  "createAppointment": "Crear Sobreturno",
  "createExamRequest": "Crear Orden de Estudio",
  "requestExam": "Solicitar Órden de Estudio",
  "exams": "Órdenes de Estudio",
  "requestRecipe": "Solicitar Receta",
  "recipes": "Recetas",
  "createRecipe": "Crear Receta",
  "addFAQ": "Enviar FAQ",
  "orYouCanSearchInOurMedicalDirectory": "O puedes buscar un médico en nuestra cartilla...",
  "searchMedicines": "Buscar medicamentos",
  "searchAnotherMedicInTheMedicalDirectory": "Buscar otro médico en cartilla",
  "typeToSearchYourMedic": "Ingresa el nombre de tu médico de cabecera para encontrarlo en Ánima",
  "typeToSearch": "Ingresa un texto de búsqueda",
  "typeAndSearch": "Ingresa un texto y apreta buscar",
  "addMedicinesToGetICD10Results": "Ingresa medicinas a tu receta para encontrar aquí los ICD10 relacionados",
  "ICD10ResultsNotFound": "No se encontraron ICD10 relacionados a las medicinas que indicaste",
  "ICD10SearchNotFound": "No se encontraron ICD10 relacionados a tu búsqueda.",
  "medicPatientBindingNotFound": "No se encontraron datos, o no se tiene acceso. Registrando acceso para posterior seguimiento de datos.",
  "searchNotFound": "No se encontraron resultados",
  "children": "Hijos/Hijas",
  "child": "Hijo/a",
  "credit_card_debit": "Tarjeta de crédito/débito",
  "pediatrician": "Médico",
  "registerDoctorsPatient": "Registrarme como paciente del Dr./a.",
  "patient": "Paciente",
  "patients": "Pacientes",
  "addCard": "Agregar ficha",
  "addPatientCard": "Agregar ficha de paciente",
  "editCard": "Editar ficha",
  "patientCard": "Ficha de paciente",
  "patientsCards": "Fichas de pacientes",
  "removePatient": "Quitar paciente",
  "copyToOtherMedic": "Copiar a otro médico",
  "myPatients": "Pacientes",
  "patientProfile": "Perfil del Paciente",
  "medic": "Especialista",
  "medics": "Especialistas",
  "myMedics": "Mis Especialistas",
  "myMedicalInsurances": "Mis Coberturas",
  "confirmAssignMedicTitle": "¿Asignar este médico?",
  "confirmAssignMedicSubtitle": "Acepta para vincular y hacer consultas con este médico",
  "confirmUnassignMedicTitle": "¿Desvincular este médico?",
  "confirmUnassignMedicSubtitle": "¿Desea desvincularse de este médico? Ya no podrá realizar consultas con este médico.",
  "noChildrenMedicsAssigned": "Tus hijos no tienen médicos vinculados",
  "noMedicsAssigned": "No tienes médicos vinculados",
  "assignThisMedic": "Vinculate con este médico",
  "unassignThisMedic": "Desvincular médico",
  "cantCreateConsultationActiveConsultation": "No puedes crear una consulta nueva mientras tengas otra consulta todavía activa.",
  "selectAtLeastAPatientToAsignMedic": "Selecciona al menos un paciente para vincular con el médico seleccionado",
  "selectMedic": "Selecciona un médico",
  "selectPatient": "Selecciona un paciente",
  "assignMedic": "Vincular médico",
  "assignedMedic": "Médico Vinculado",
  "assignedMedics": "Médicos Vinculados",
  "assignMoreMedic": "Vincula más médicos",
  "assignMoreMedic2": "Puedes vincular médicos de distintas especialidades y hacer consultas para usted y/o sus hijos",
  "medicAssignedSuccesfully": "Médico Vinculado con Éxito",
  "confirmUnassignMedic": "¿Confirma que desea remover este médico? Esta acción NO se puede deshacer.",
  "confirmActivateMedic": "¿Confirma que desea reactivar este médico? El médico volverá a aparecer en los listados de la clínica nuevamente",
  "confirmDeactivateMedic": "¿Confirma que desea desactivar este médico? El médico dejará de aparecer en los listados de la clínica hasta que se active nuevamente",
  "activateMedic": "Activar médico",
  "deactivateMedic": "Desactivar médico",
  "unassignMedic": "Remover médico",
  "unassignedMedic": "El médico ha sido removido",
  "updateAppTitle": "Debe actualizar la aplicación para poder continuar.",
  "updateAppMessage": "Su versión instalada es demasiado antigua.",
  "updateText": "Actualizar",
  "loading": "Cargando",
  "profileImage": "Imagen de perfil",
  "changeProfileImage": "Cambiar imagen de perfil",
  "uploadingImage": "Subiendo imagen...",
  "whatsappShareInvitationTitle": "Invita pacientes nuevos",
  "whatsappShareInvitationTitlePatients": "Invita a tú medico",
  "whatsappShareInvitationSubtitle": "Enviales una invitación para vincularse contigo a través de Ánima",
  "whatsappShareInvitationButtonText": "Invitar por WhatsApp",
  "whatsappShareButtonText": "Compartir por WhatsApp",
  "rnShareButtonText": "Compartir documento",
  "whatsapp_share_text_intro_patients": "*Hola, soy",
  "whatsapp_share_text_intro": "*Soy tu médico/a",
  "whatsapp_share_text": " recibo consultas como las tuyas vía WhatsApp a diario... pero *ahora uso ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Por favor escríbeme directo por esta app. Es más segura, y hace que *toda tu información*, como la de todos mis pacientes esté *resguardada y ordenada*.\n\n Te invito a usarla descargándola para Android desde https://play.google.com/store/apps/details?id=com.animarn.animamedica o para iPhone desde https://apps.apple.com/ar/app/%C3%A1nima-ecuador/id1487244370?l=es. \n\n *Además* te puedo generar *recetas online* en cualquier momento, que te servirán para *reembolso* con tu aseguradora.\n\n Puedes ver mas info aquí: http://www.appanima.com",
  "whatsapp_share_text_patients": " *Te invito a usar ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Es más segura, y hace que *toda nuestra información* esté *resguardada y ordenada*.\n\n Te invito a usarla descargándola para Android desde https://play.google.com/store/apps/details?id=com.animarn.animamedica o para iPhone desde https://apps.apple.com/ar/app/%C3%A1nima-ecuador/id1487244370?l=es. \n\n *Además* podemos generar *recetas online* en cualquier momento, que servirán para *reembolso* en nuestra aseguradora.\n\n Puedes ver mas info aquí: http://www.appanima.com",
  "whatsapp_share_text_success": "Gracias por invitar a tus pacientes a unirse",
  "whatsapp_share_text_error": "No se pudo compartir por WhatsApp, pero aún puedes invitar a tus pacientes a descargar la aplicación desde http://appanima.com/, a buscarte y a contactarte directamente.",
  "shareChatTitle": "Comparte por chat",
  "shareChatSubtitle": "Elige el chat de la consulta en la que quieras compartir",
  "completeAllFields": "Complete todos los campos",
  "confirmLogoutModal": {
    "title": "¿Está seguro?", "subtitle": "¿Quiere cerrar sesión?", "accept": "Salir", "cancel": "Cancelar"
  },
  "confirmActionModal": {
    "title": "¿Está seguro?",
    "subtitle": "Esta acción no se puede deshacer",
    "accept": "Sí, estoy seguro",
    "cancel": "Cancelar"
  },
  "successModal": {
    "title": "Éxito",
    "message": "¡Todo ha salido bien!",
    "profileImageUploaded": "Imagen de perfil actualizada con éxito",
  },
  "errorModal": {
    "title": "Error",
    "setPrice": "Configura un precio",
    "checkData": "Revise sus datos",
    "accept": "Aceptar",
    "apiGetConfigError": "Hubo un error al obtener la configuración del evento",
    "selectAPerformanceAtLeast": "Elija la función a la que quiere asistir",
    "selectASectorAtLeast": "Elija el sector deseado",
    "selectASeatAtLeast": "Elija al menos un asiento",
    "cantBuyMoreTickets": "No se pueden comprar más asientos de los disponibles",
    "availableTicketsForThisSector": "Tickets disponibles hasta el momento para este sector",
    "chooseDesiredQuantity": "Elija la cantidad deseada",
    "completeWithYourRegisteredEmail": "Complete su dirección de correo registrada",
    "completeWithPatientUserEmail": "Ingrese la dirección de e-mail del paciente",
    "completeWithAtLeastOneEmail": "Ingrese al menos una dirección de correo electrónico",
    "completeWithAnAmount": "Ingrese el importe",
    "completeAllFormFields": "Complete todos los campos del formulario",
    "selectAMedicToAddPatient": "Seleccione un médico para agregarle la ficha",
    "selectAPatient": "Seleccione un paciente",
    "selectAnAppointmentType": "Seleccione el tipo de turno",
    "emailsDontMatch": "Los e-mails no coinciden",
    "checkTimeonze": "Revise el huso horario seleccionado",
    "checkSingleDate": "Debe elegir un día de atención",
    "passwordsDontMatch": "Las contraseñas no coinciden",
    "unexpectedError": "Ha ocurrido un error inesperado",
    "sendImageError": "La imagen no se ha podido subir. Revisa que no sea muy pesada e intenta nuevamente.",
    "cuitFormat": "El CUIT debe tener el formato XX-XXXXXXXX-X",
  },
  "completeWithAnAmountToCharge": "Ingrese el importe a cobrar",
  "completeWithAnEmail": "Ingrese un correo electrónico",
  "optionalEmail": "Correo electrónico (opcional)",
  "checkoutSuccessModal": {
    "title": "Éxito", "subtitle": "La compra ha sido exitosa",
  },
  "checkoutErrorModal": {
    "title": "Error", "subtitle": "Ha ocurrido un error al procesar su pago. Revise sus datos.",
  },
  "infoModal": {
    "title": "Información",
    "checkData": "Revise sus datos",
    "accept": "Aceptar",
    "disabledSeat": "Este asiento no está disponible. Por favor, elija otro.",
    "filteredSeat": "No puede elegir asientos de distintos sectores."
  },
  "quotationExpiredErrorModal": {
    "title": "Error",
    "subtitle": "Ha pasado demasiado tiempo para confirmar este viaje. Por favor, solicite un viaje nuevo.",
    "accept": "Aceptar"
  },
  "userDeniedGeolocationErrorModal": {
    "title": "No se pudo obtener su ubicación",
    "subtitle": "Debe conceder permisos de geolocalización a nuestra app para poder hacer uso de esta funcionalidad",
    "accept": "Aceptar"
  },
  "functionNotGuaranteed": "El correcto funcionamiento de esta prestación no está garantizado. Por favor, revise los permisos de la aplicación",
  "aUserHasDisconnected": "Un usuario se ha desconectado",
  "yes": "Sí",
  "no": "No",
  "age": "Edad",
  "year": "Año",
  "years": "Años",
  "month": "Mes",
  "months": "Meses",
  "day": "Día",
  "days": "Días",
  "role": "Rol",
  "no_patients": "No tienes pacientes vinculados",
  "enjoy_ride": "¡Disfruta del viaje!",
  "wait_please": "Espere, por favor",
  "domain": "Patente",
  "driver_waiting_at_origin": "El chófer está esperandote",
  "currency_sign": "$",
  "hotErrorMsg": "Envía un e-mail al administrador del sitio si no consigues finalizar tu compra con éxito",
  "totalPrice": "Valor total",
  "price": "Valor",
  "prices": "Valores",
  "pricePlaceholder": "Al menos debe ser 0 o superior",
  "pricesPlaceholder": "Si no agrega ningún precio, figura como 'A Consultar'",
  "pricesEmptyText": "Si no agrega ningún precio, figura como 'A Consultar'",
  "addPrice": "Agregar precio",
  "priceTitle": "Agregue un título descriptivo",
  "priceTitlePlaceholder": "Algo más sobre este precio",
  "priceDescription": "Añade algún detalle útil para el paciente",
  "priceDescriptionPlaceholder": "Más detalles sobre este precio de consulta",
  "setPriceToContinueEvenZero": "Configure un precio para continuar, aunque sea $0",
  "quotate_trip": "Cotizar Viaje",
  "google_directions_failed": "La solicitud de direcciones falló por lo siguiente:",
  "select_origin": "Selecciona origen del viaje",
  "country": "País",
  "accept": "Aceptar",
  "seeMyPublicProfile": "Ver mi perfil público",
  "seeMyPatientShareLink": "Visitar el enlace",
  "username": "Nombre de Usuario",
  "phoneNumber": "Teléfono",
  "phone_number": "Teléfono Móvil (Sólo Celular)",
  "phone_number_example": "911 2345-6789",
  "telephone_number": "Teléfono",
  "telephone_number_example": "2345-6789",
  "oldPassword": "Contraseña Actual",
  "passwordValidation": "La contraseña debería contener al menos 6 caracteres.",
  "newPassword": "Contraseña Nueva",
  "password": "Contraseña",
  "repeatEmail": "Repetir E-mail",
  "repeatPassword": "Repetir Contraseña",
  "changePassword": "Cambiar Contraseña",
  "login": "Iniciar sesión",
  "linkWithMedic": "Asociar al médico",
  "new_user": "¿Usuario nuevo?",
  "sign_up": "Registrarse",
  "sign_in": "Iniciar sesión",
  "forgotYourPassword": "¿Olvidaste tu contraseña?",
  "forgot": "¿Olvidaste",
  "yourPassword": "tu contraseña?",
  "or": "o",
  "or_continye_with": "O CONTINÚA CON",
  "facebook": "Facebook",
  "twitter": "Twitter",
  "instagram": "Instagram",
  "linkedIn": "LinkedIn",
  "publicEmail": "E-mail público (visible a los usuarios)",
  "publicPhone": "Teléfono público (visible a los usuarios)",
  "timezone": "Huso horario predeterminado",
  "google": "Google",
  "first_name": "Nombre",
  "email_address": "E-Mail",
  "address": "Dirección del lugar",
  "last_name": "Apellido",
  "preexisting_diseases": "Antecedentes. Ej.: alergias, etc.",
  "takes_medication": "¿Toma alguna medicación?",
  "preexisting_medication_by_comma": "Remedios separados por coma",
  "preexisting_medication": "¿Toma alguna medicación?",
  "preexistences": "Antecedentes",
  "medication": "Medicación",
  "medications": "Medicamentos",
  "resendDocument": "Reenviar Documento",
  "identification": "DNI / Cédula / Pasaporte",
  "cuit": "CUIT",
  "number": "Número",
  "numberOf": "Número de",
  "create_password": "Generar Contraseña",
  "confirm_password": "Confirmar Contraseña",
  "sign_up_now": "Registrarse ahora",
  "already_registered": "¿Ya tienes usuario?",
  "verification": "Verificación",
  "next": "Siguiente",
  "enter_confirmation_code": "Ingresa tu código de confirmación",
  "sent_to_you_via_sms": "enviado a través de SMS !",
  "skip": "Saltear",
  "add_money_to_your_wallet_for": "Agregar dinero a tu billetera virtual por",
  "quick_payment": "pago rápido",
  "card_number": "Número de Tarjeta",
  "expiry_date": "Fecha de Expiración",
  "cvv_code": "Código de la Tarjeta",
  "inter_amout_in": "Ingresar monto (en $)",
  "referral_code": "¿Tienes un código de referencia?",
  "add_referral_code": "Agregar código de referencia y",
  "first_cab_ride_free": "obtén tu primer viaje con descuento.",
  "i_dont_have": "No tengo",
  "continue": "Continuar",
  "add_6_digit_referral_code": "Ingresa tu código de referencia de 6 digitos",
  "from": "Desde",
  "to": "Hasta",
  "tab_to_add_location": "Tap para agregar locación",
  "your_location": "Tu localización",
  "wallet": "Billetera Virtual",
  "movement": "Movimiento",
  "movements": "Movimientos",
  "cash": "Efectivo",
  "choose_cab_type": "Elige el tipo de móvil",
  "request_cab": "Solicitar vehículo",
  "schedule_pick_up": "Agendar solicitud de vehículo",
  "select_date": "Seleccionar Fecha",
  "date": "Fecha",
  "prescriptionDate": "Fecha de la receta",
  "creationDate": "Fecha de creación",
  "select_time": "Seleccionar Hora",
  "initValidation": "Comenzar",
  "sendValidationCode": "Enviar Código",
  "resendValidationCode": "Reenviar Código",
  "telephoneAlreadyValidated": "Tu teléfono ya está validado",
  "telephoneValidationSuccessfull": "Tu teléfono ha sido validado correctamente. Ahora recibirás las notificaciones en tu dispositivo móvil.",
  "telephoneNotYetValidated": "No has validado tu teléfono y no podremos enviarte alertas importantes.",
  "registerFillUpYourMedicProfile": "Completa tu perfil para que tus pacientes comprendan mejor tus servicios",
  "doItNow": "Hazlo ahora desde aquí.",
  "yesCancelAppointment": "Cancelar turno",
  "noCancelAppointment": "Conservar turno",
  "cancel": "Cancelar",
  "confirm": "Confirmar",
  "confirm_trip": "¿Confirmar Viaje?",
  "finding_your_ride": "Buscando Tu Viaje",
  "driver_info": "Información del Chófer",
  "cancel_ride": "Cancelar Viaje",
  "arriving_in": "Llegando en",
  "contactThisDoctorToSharePatients": "Contactate con este médico para derivarle pacientes",
  "contact": "Contacto",
  "trip_info": "Información del Viaje",
  "distance": "Distancia",
  "traveling_time": "Tiempo de recorrido",
  "arrival": "Arribo",
  "goBack": "Volver",
  "pay": "Pagar",
  "payment": "Pago",
  "receipt": "Recibo",
  "fare_summary": "Resumen de Tarifas",
  "cash_pay": "Pagar en Efectivo",
  "wallet_pay": "Pago con Billetera Virtual",
  "choose_payment_mode": "Cambiar método de pago",
  "submit_rating": "Calificar y pagar",
  "rate_now": "Calificar ahora",
  "view_profile": "Ver Perfil",
  "home": "Inicio",
  "my_trips": "Mis viajes",
  "help": "Ayuda",
  "blog": "Blog",
  "refer_and_earn": "Referir y ganar",
  "logout": "Cerrar Sesión",
  "logoutModal": {
    "title": "Cerrar Sesión", "subtitle": "¿Está seguro de querer cerrar sesión?",
  },
  "contact_number": "Telefóno",
  "your_referral_code": "Tu Código de Referencia",
  "referral_code_meassage": "Comparte este código de referencia y obtén un 30% de descuento en tu próximo viaje",
  "past": "Pasado",
  "upcoming": "Próximamente",
  "balance": "Balance",
  "available_balance": "Saldo Disponible",
  "send_to_bank": "Enviar a tu Banco",
  "add_money": "+ Agregar dinero",
  "enter_bank_info": "Ingrese Información del Banco",
  "account_number": "Nº de Cuenta",
  "account_holder_name": "Titular de la Cuenta",
  "bank_code": "CBU",
  "enter_amount_to_transfer": "Ingrese monto a transferir",
  "enter_amount_to_transfer_in": "Ingrese monto a transferir (en $)",
  "proceed": "Proceda",
  "trips_and_fare": "Viajes y Tarifas",
  "choose_your_issue": "Elige tu inconveniente",
  "any_issue_regarding_your_trip_and_fare": "Inconvenientes con tus viajes o tarifas",
  "problem_while_paying_fare_or_related_issue": "Problemas con tus pagos",
  "app_usability": "Usabilidad de la Aplicación",
  "any_issue_while_using_our_app": "Problemas para usar la apicación",
  "account": "Cuenta",
  "your_account_info_can_t_change_details_or_change_password": "La información de tu cuenta no se puede modificar",
  "consult": "Consultar",
  "pickConsultationType": "Elige el tipo de consulta",
  "basicInformation": "Información Básica",
  "filters": "Filtros",
  "medicalRecordTypesNotFound": "No se encontraron tipos de hitos clínicos",
  "medicalInfoNoData": "Sin datos",
  "anErrorHasOcurred": "Ha ocurrido un error",
  "preexistingDiseases": "Enfermedades Preexistentes",
  "chronicMedication": "Medicación Crónica",
  "habits": "Hábitos",
  "familyBackground": "Antecedentes Familiares",
  "mailing": "Mensajes automatizados",
  "agenda": "Agenda",
  "showing": "Mostrando",
  "of": "de",
  "first": "Primero",
  "previous": "Anterior",
  "last": "Primero",
  "cardHolder": "Nombre Completo",
  "expires": "Vence",
  "expirationDate": "Fecha de vencimiento",
  "dragFilesHere": "Puedes arrastar tus imagenes aquí",
  "prescriptionDates": "Esta es la fecha de prescripción de la receta. Si añade múltiples fechas, se generará una receta por cada fecha que agregue, ideal para emitir varias copias de esta receta con diferentes fechas cada una. Útil para tratamientos prolongados o crónicos que necesitan varias recetas en el año.",
  "prescriptionMedicines": "Agrega aquí los medicamentos que quieras recetar",
  "addDate": "Agregar fecha",
  "addAnotherDate": "Agregar otra fecha",
  "removeDate": "Quitar fecha",
  "noResults": "No se obtuvieron resultados",
  generic: 'Genérico',
  prescriptOnlyGeneric: 'Recetar sólo genérico',
  noReplacement: 'No sustituir',
  needsDuplicate: 'Por duplicado',
  logo: 'Logo',
  chooseAScheduleAtLeast: 'Elija al menos un horario',
  successTestProfileSubtitle: 'Su receta de prueba se ha generado exitosamente.',
  gatewayMethodsList: {
    manual_ars: {
      "label_medic": "A convenir con el paciente (Pesos Argentinos)",
      "label_patient": "A convenir con el médico (Pesos Argentinos)",
    },
    manual_usd: {
      "label_medic": "A convenir con el paciente (Dólares Estadounidenses)",
      "label_patient": "A convenir con el médico (Dólares Estadounidenses)",
    },
    bank_transfer_ars: {
      "label_medic": "Transferencia Bancaria (Pesos Argentinos)",
      "label_patient": "Transferencia Bancaria (Pesos Argentinos)",
    },
    bank_transfer_usd: {
      "label_medic": "Transferencia Bancaria (Cuenta en USD)",
      "label_patient": "Transferencia Bancaria (Pago en USD)",
    },
    mercado_pago_ars: {
      "label_medic": "Pago con Tarjeta de débito o crédito (con MercadoPago) (Pesos Argentinos)",
      "label_patient": "Pago con Tarjeta de débito o crédito (Pesos Argentinos)",
      "title": "Te llevaremos a MercadoPago",
      "subtitle": "MercadoPago es un sitio seguro para que puedas pagar con tarjeta de débito, crédito y otros medios de pago disponibles.",
    },
    paypal_usd: {
      "label_medic": "Pago con Tarjeta de débito o crédito (con Paypal en USD)",
      "label_patient": "Pago con Tarjeta de débito o crédito (en USD)",
      "title": "Te llevaremos a Paypal",
      "subtitle": "Paypal es un sitio seguro para que puedas pagar con tarjeta de débito, crédito y otros medios de pago disponibles.",
    }
  },
  before_payment_type: {
    percent: "Cobro por porcentaje",
    fixed: "Cobro por valor fijo"
  },
  beforePaymentTypeNotSet: "Configura una forma de cobro",
  beforePaymentAmountNotSet: "Configura un monto o un porcentaje de cobro distinto de cero.",
  enterYourAddress: 'Ingresá tu dirección',
  genericErrorContactSiteAdministrator: 'Ha ocurrido un error. Contactate con el administrador del sitio para que podamos ayudarte',
  genericNoGatewayConnected: 'No pudimos cargar la pasarela de pagos. Avísale al médico para que pueda configurarla correctamente.',
  genericNoPaymentMethodsConfigured: 'No pudimos cargar los métodos de pagos. Avísale al médico para que pueda configurarlos correctamente.',
  shareMyProfile: 'Compartir mi perfil',
  invitePatient: 'Invitar paciente',
  watchVideo: 'Ver video',
  patientDataModal: {
    title: 'Su doctor necesita sus datos',
    content: ' solicita que complete sus datos para enviarle sus recetas médicas de forma ágil y personalizada, agendar sus datos para próximos turnos y poder gestionar su historial clínico con mayor facilidad.',
    sentDataSuccessfully: 'Gracias, los datos han sido enviados correctamente.',
    setWelcomeFalseButtonText: 'Ingresar mis datos',
  },
  hasAPatientUserAlready: '¿Ya tienes un usuario paciente en MediCloud?',
  noHashFound: 'No se encontró el hash',
  "mustPayPrescription": "Debes abonar esta prescripción para tener acceso a ella",
  "prescriptionNotFound": "No se ha encontrado la receta. Escríbenos a info@medicloud.com.ar para que podamos ayudarte.",
  "medicloud-event-status-scheduled": "Tomado",
  "medicloud-event-status-pending": "Pendiente",
  "medicloud-event-status-confirmed": "Confirmado",
  "medicloud-event-status-waiting_room": "Sala de Espera",
  "medicloud-event-status-attending": "Atendiendo",
  "medicloud-event-status-completed": "Atendido",
  "medicloud-event-status-patient_missed": "Inasistencia paciente",
  "medicloud-event-status-patient_canceled": "Cancelado por el paciente",
  "medicloud-event-status-medic_canceled": "Cancelado por el médico",
  noMoreRecords: 'No hay más registros',
  exportPDFMedicalRecordSubtitle: 'Seleccione el tipo de evoluciones que quiere incluir',
  exportPDFMedicalRecord: 'Descargar HC en PDF',
  addMedicalRecord: 'Evolucionar paciente',
  addMedicalStudy: 'Agregar estudio',
  medicalRecordTitlePlaceholder: 'Título descriptivo del hito clínico',
  medicalRecordDescriptionPlaceholder: 'Descripción relevante de esta evolución',
  medicalRecordInternalNotesPlaceholder: 'Anotaciones privadas sólo visibles por el médico. No se comparten con el paciente.',
  medicalRecordWeightPlaceholder: 'Peso del paciente',
  medicalRecordHeightPlaceholder: 'Altura del paciente',
  medicalStudyType: 'Tipo de estudio',
  medicalStudyTypePlaceholder: 'Elige un tipo de estudio',
  medicalStudyTitlePlaceholder: 'Título descriptivo del estudio',
  medicalStudyCommentPlaceholder: 'Comentarios del estudio',
  medicalStudyFilePlaceholder: 'Puedes adjuntar archivos pdf, png o jpg',
  file: 'Archivo',
  medicalRecordsNotFoundYet: 'No se han registrado historias clínicas aún',
  medicalStudiesNotFoundYet: 'No se han registrado estudios clínicos aún',
  time: 'Tiempo',
  fullCalendarStartTime: 'Hora mín.',
  fullCalendarEndTime: 'Hora máx.',
  fullCalendarSlotDuration: 'Duración',
  selectStartTime: 'Elige la hora mínima',
  selectEndTime: 'Elige la hora máxima',
  notes: 'Notas',
  notesPlaceholder: 'Notas privadas con información a destacar, por ejemplo Obras sociales del paciente, comentarios, etc.',
  announcePatient: 'Llamar paciente',
  nameNotFound: 'Nombre no encontrado',
  view: 'Ver',
  yourClinic: 'Tu centro',
  cleanFilters: 'Limpiar filtros',
  createPrescriptionWithoutInsurance: 'Hacer receta sin cobertura',
  noInsurancePlanName: 'Cobertura Eliminada',
  clinicPatientsHandleShareActivated: 'La historia clínica de los pacientes ES COMPARTIDA con los otros médicos del equipo',
  clinicPatientsHandleShareNotActivated: 'La historia clínica de los pacientes NO se comparte con los otros médicos del equipo',
  clinicPatientsHandleShareContactUs: 'Si quieres cambiar esta configuración, ponte en contacto con nosotros',
  clinicPatientsHandleShareNoMedics: 'No se encontraron fichas de este paciente',
  export: 'Exportar',
};

export default locales_es;
